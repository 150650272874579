<template>
  <third-party-cookies-container/>
</template>

<script>

  import ThirdPartyCookiesContainer
    from '@/entities/terms/features/third-party-cookies/third-party-cookies.container';

  export default {
    name: 'ThirdPartyCookiesView',
    components: { ThirdPartyCookiesContainer },
    props: {},

    data() {
      return {};
    },

    computed: {},

    methods: {},

  };
</script>

<style lang="scss" scoped>

</style>
